<template>
	<section class="container">
		<b-card header="Rapport des visites partie Logiciel">
			<!-- https://matomo.org/faq/reports/embed-a-matomo-report-in-a-html-page/ -->
			<b-embed
				type="iframe"
				aspect="4by3"
				src="https://piwik.qualiteval.fr/index.php?module=Widgetize&action=iframe&moduleToWidgetize=Dashboard&actionToWidgetize=index&idSite=1&period=month&date=yesterday&token_auth=b87bc4364d2d277f2ff8eeb787422931"
				title="Rapport des visites"
			></b-embed>
		</b-card>
		<!-- <b-card-group deck class="mt-2">
			<b-card title="Licences">
				<b-button :to="{ name: 'licences' }"> Suivi des licences </b-button>
			</b-card>
			<b-card title="Ventes">
				<b-button :to="{ name: 'ventes' }">Suivi des ventes</b-button>
			</b-card>
			<b-card title="Clients">
				<b-button :to="{ name: 'clients' }">Suivi des clients</b-button>
			</b-card>
			<b-card title="Référentiels">
				<b-button :to="{ name: 'referentiels' }"> Gérer les référentiels </b-button>
			</b-card>
			<b-card title="Visites">
				<b-button href="https://piwik.qualiteval.fr"> Accéder à Matomo </b-button>
			</b-card>
		</b-card-group> -->
		<div class="font-italic text-center my-1">Version {{ $store.getters.appVersion }}</div>
	</section>
</template>

<script>
export default {
	name: 'homeView'
};
</script>
